import React from "react"
import EllipsisLoader from "../zzz/organisms/ellipsisLoader"
import { navigate } from "gatsby"

// LEGACY: This code should never have to run
const OrderRedirectPage = ({ location }) => {
  if (typeof window !== "undefined" && window.top) {
    if (window.self !== window.top) {
      window.top.location.href = window.location.href
    } else {
      navigate(`/order-status/${location.search}&p`)
    }
  }
  // We should make this a special "order success redirect" component so that it will load faster. Rendering layout is expensive
  return <EllipsisLoader message="You are being redirected" />
}

export default OrderRedirectPage
